import * as _ from '../object';

/**
 * @param {Object} tarif
 * @param {Object} lePackData
 * @returns {number|null}
 */
export function resolvePrice(tarif, lePackData) {
    const { offre, type:offerType } = lePackData;
    const price = tarif.price;

    return reduce(price, [offre, offerType]);
}

/**
 * @param {Object} tarif
 * @param {Object} lePackData
 * @returns {number|null}
 */
export function resolveNbrOfUsers(tarif, lePackData) {
    const { offre, type:offerType } = lePackData;
    const nbrOfUsers = tarif.nbrOfUsers;

    return reduce(nbrOfUsers, [offre, offerType]);
}

/**
 * Reduce a tarif property object to a value by applying dependencies to him
 * In the case of Le Pack product, dependencies are offer and offer type.
 *
 * @param {Object|string|number|boolean} value
 * @param {Array} dependencies
 * @returns {any|null}
 */
function reduce(value, dependencies) {
    if (!_.isPureObject(value)) {
        return value;
    }

    const [offre, type] = dependencies;

    if (_.has(value, offre)) {
        return type in value[offre] ? value[offre][type] : null;
    }

    return type in value ? value[type] : null;
}
