import React from 'react';
import styled from 'styled-components';
import {SubscriptionContainer} from '../Utils/styleConstants';
import {getImage} from "../Utils/jsonInterpreter";

const Image = styled.img`
    padding: 0 5% 3vh 3%;
    width: 100%;
`;

const AbonnementPrevisite = props => {
    return (
        <SubscriptionContainer>
            <Image src={getImage(props.img)} alt={props.alt} />
        </SubscriptionContainer>
    );
};

export default AbonnementPrevisite;
